<script setup lang="ts">
import { usePlayerStore } from '~/stores/usePlayerStore'

const playerStore = usePlayerStore()
const { $viewport } = useNuxtApp()
</script>

<template>
  <div class="min-w-80 relative">
    <div
      v-if="$viewport.isGreaterOrEquals('lg')"
      class="lg:flex hidden w-60 h-screen p-4 fixed flex-col gap-8 bg-zeno-gray-light z-10"
      :class="{ 'pb-28': playerStore.media }"
    >
      <div class="lg:pl-4">
        <MainLogo />
      </div>

      <MainNavigation />
    </div>

    <div v-else class="lg:hidden z-10">
      <MobileNavigation />
    </div>

    <slot />
  </div>
</template>

<style scoped></style>
